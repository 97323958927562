import React from "react";
import { useMediaQuery } from "@material-ui/core";
import { useStyles } from "./BannerCoolebra.style";
import {
  BANNER_COOLEBRA_DESKTOP,
  BANNER_COOLEBRA_MOBILE,
} from "images/GoogleCloud";

interface propsContact {
  noForm?: boolean;
}

const BannerCoolebra = ({ noForm }: propsContact) => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 425px)");

  return (
    <section className={classes.container}>
      <img src={!isMobile ? BANNER_COOLEBRA_DESKTOP : BANNER_COOLEBRA_MOBILE} />
      {/* eslint-disable-next-line jsx-a11y/anchor-has-content*/}
      <a
        className={classes.href}
        href="https://www.coolebra.com/"
        target={"_blank"}
        rel="noreferrer"
      />
    </section>
  );
};

export default BannerCoolebra;
