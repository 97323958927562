import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    position: "relative",
  },
  flex: {
    display: "flex",
    justifyContent: "center",
    gap: "3rem",
    [theme.breakpoints.down(1240)]: {
      flexDirection: "column",
    },
  },
  left: {
    width: "21rem",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    [theme.breakpoints.down(1240)]: {
      width: "100%",
    },
  },
  right: {
    background: "#0d3038",
    display: "grid",
    gap: "1.5rem",
    padding: "1.5rem 3rem",
    borderRadius: "15px",
    width: "46rem",
    [theme.breakpoints.down(1240)]: {
      maxWidth: "300px",
      margin: "0 auto 2rem",
    },
  },
  noForm: {
    background: "#0d3038",
    display: "grid",
    gap: "1.5rem",
    padding: "1.5rem 3rem",
    borderRadius: "15px",
    width: "30rem",
    [theme.breakpoints.down(1240)]: {
      maxWidth: "300px",
      margin: "0 auto 2rem",
    },
  },
  title: {
    fontSize: "1.75rem",
    fontWeight: 500,
    [theme.breakpoints.down(1240)]: {
      margin: "0 auto",
      width: 300,
    },
  },
  mensaje: {
    margin: "1rem auto 0",
    width: "25rem",
    [theme.breakpoints.down(1240)]: {
      fontSize: "0.7rem",
      width: "18rem",
    },
  },
  subtitle: {
    fontWeight: 300,
    fontSize: "0.875rem",
    opacity: 0.6,
    width: "15rem",
    [theme.breakpoints.down(1240)]: {
      maxWidth: "300px",
      margin: "0 auto",
      minWidth: 300,
    },
  },
  noFormSubtitle: {
    fontWeight: 300,
    fontSize: "0.875rem",
    opacity: 0.6,
    width: "21rem",
    [theme.breakpoints.down(1240)]: {
      maxWidth: "300px",
      margin: "0 auto",
    },
  },
  subtitle2: {
    fontWeight: 300,
    fontSize: "0.875rem",
    opacity: 1,
    [theme.breakpoints.down(1240)]: {
      maxWidth: "300px",
    },
    "& span": {
      fontWeight: "bold",
      opacity: 1,
    },
  },
  email: {
    display: "flex",
    margin: "0 auto",
    justifyContent: "center",
    alignItems: "center",
    width: 200,
    "& button": {
      padding: "0.5rem 0 0.5rem 0.5rem",
      marginLeft: "1rem",
      height: 50,
      minWidth: "3rem",
      "& span": {
        marginRight: "0",
      },
    },
    "& .button": {
      color: "white",
      width: "180px",
    },
    "& >div >div, fieldset": {
      border: "1px solid white",
      width: "20rem",
      color: "white",
      borderRadius: 12,
      margin: "0 auto",
      borderColor: "white!important",
      display: "flex",
      [theme.breakpoints.down(1240)]: {
        width: "213px",
      },
    },
  },
  partners: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    margin: "4rem auto 0",
    maxWidth: "62rem",
    [theme.breakpoints.down(1240)]: {
      maxWidth: "300px",
      margin: "0 auto",
      flexWrap: "wrap",
    },
    "& img": {
      width: "8rem",
      height: "4rem",
      opacity: 0.3,
      [theme.breakpoints.down(1240)]: {
        width: "6rem",
        height: "3rem",
        margin: "2rem 0 1rem",
      },
    },
  },
  containerButtons: {
    display: "flex",
    gap: "1rem",
    justifyContent: "center",
    alignItems: "center",
    "& .firstButton": {
      width: 130,
      color: "white",
    },
    "& .lastButton": {
      minWidth: 30,
      width: 60,
      background: theme.palette.primary.dark,
      "& span": {
        margin: 0,
      },
    },
  },
  href: {
    width: "30%",
    height: 130,
    position: "absolute",
    top: "50%",
    right: "21%",
    cursor: "pointer",
    [theme.breakpoints.down(1000)]: {
      height: 90,
    },
    [theme.breakpoints.down(425)]: {
      width: "80%",
      top: "73%",
      right: "10%",
    },
  },
}));
